<template>
  <div class="view view--styleguide">

    <collapse-transition>
      <div class="view-header" v-if="pageTitle && showViewHeader">
        <div class="view-header__header">
  <!--        <Pagination from="1" to="25" total="2572" />-->
          <Btn class="action-btn" text="Main action button" icon="plus" color="primary" />
        </div>
        <div class="view-header__footer">
          <Btn class="back-btn" round grow icon="arrow-left" href="#" />
          <h1 class="page-title" v-html="pageTitle"></h1>
        </div>
      </div>
    </collapse-transition>

    <div class="view-body">
      <collapse-transition>
        <Section class="section--styleguide-nav" v-if="showViewHeader">
          <Container>
            <nav class="styleguide-nav">
              <router-link :to="{ name: 'colors' }">Colors</router-link>
              <router-link :to="{ name: 'typography' }">Typography</router-link>
              <router-link :to="{ name: 'icons' }">Icons</router-link>
              <router-link :to="{ name: 'buttons' }">Buttons</router-link>
              <router-link :to="{ name: 'layout' }">Layout</router-link>
              <router-link :to="{ name: 'forms' }">Forms</router-link>
              <router-link :to="{ name: 'forms-advanced' }">Forms advanced</router-link>
              <router-link :to="{ name: 'lists' }">Lists</router-link>
              <router-link :to="{ name: 'tables' }">Tables</router-link>
              <router-link :to="{ name: 'micro-parcels' }">Micro-parcels</router-link>
              <router-link :to="{ name: 'misc' }">Misc</router-link>
              <router-link :to="{ name: 'search' }">Search</router-link>
              <router-link :to="{ name: 'fetchservice' }">Fetch service</router-link>
            </nav>
          </Container>
        </Section>
      </collapse-transition>

      <Section class="section--styleguide section--scroll">
        <Container>

          <router-view />

        </Container>
      </Section>
    </div>

    <collapse-transition>
      <div class="view-footer" v-if="showViewFooter">
        <div class="view-actions view-actions--left">
          <Btn
            class="action-btn"
            text="Demo footer button"
          />
          <Btn
            class="action-btn"
            text="Demo footer button"
            icon="create"
            color="primary"
          />
        </div>
      </div>
    </collapse-transition>

  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
// import Pagination from '@/components/list/Pagination.vue'
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue'

export default {
  name: 'StyleguideView',

  components: {
    Btn,
    // Pagination,
    Section,
    Container,
    CollapseTransition,
  },

  props: {
    pageTitle: {
      type: String,
    },
  },

  data() {
    return {
      showViewHeader: true,
      showViewFooter: false,
    }
  },

  mounted() {
    // Listen to pub/sub bus events
    this.emitter.on('scroll', (data) => {
      // console.log('SCROLL!', data)
      this.showViewHeader = (data.scrollPosition === 'top' || data.scrollDirection === 'up')
      this.showViewFooter = data.scrollPosition === 'bottom'
    })
  },
}
</script>

<style lang="scss" scoped>
/* STYLEGUIDE */

// SECTIONS

:deep(.section--styleguide) {
  @include v-padding($gutter);
}
</style>

<style lang="scss">
/* STYLEGUIDE - Magic comment - CSS breaks if removed!!! */

.section--styleguide-nav {
  padding-top: $gutter-quarter !important;
  padding-bottom: $gutter-quarter !important;
  border-bottom: 1px solid $color-gray-lighter;
}

.styleguide-nav {
  display: flex;
  flex-wrap: wrap;
  gap: $gutter-half;

  > a {

    &.router-link-exact-active {
      font-weight: $font-weight-bold;
    }
  }
}
</style>
